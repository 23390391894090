import { Breakpoint, Color } from '@elearning-platform/ui';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { Footer } from '../footer/footer.component';
import { FooterVariant } from '../footer/footer.enums';
import { Header } from '../header/header.component';

import {
  Container,
  Content,
  Headline,
  Img,
  ImgWrapper,
  Span,
  StyledShape,
  Text,
} from './success.styled';
import { SuccessProps } from './success.types';

export const Success: React.FC<SuccessProps> = ({
  headline,
  primaryText,
  secondaryText,
  imageLarge,
  imageSmall,
  shape,
}: SuccessProps): JSX.Element => {
  const isTablet: boolean = useMediaQuery({ query: Breakpoint.Tablet });

  return (
    <Container>
      <Header />
      <Content>
        <Headline>{headline}</Headline>
        <Text>
          {primaryText}
          {' '}
          <Span>{secondaryText}</Span>
        </Text>
      </Content>
      <ImgWrapper $shape={shape}>
        <Img src={isTablet ? imageLarge : imageSmall} />
        <StyledShape color={Color.Brand300} variant={shape} />
      </ImgWrapper>
      {!isTablet && <Footer $variant={FooterVariant.Success} />}
    </Container>
  );
};
