import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { ButtonColor } from "../../../../components/button/button.constants";
import { ScrollLessonActions } from "../../redux/scrollLesson.reducer";

import Answer from "./answer.component";
import {
  Container,
  Counter,
  AllQuestionsCounter,
  Separator,
  Title,
  Answers,
  ButtonWrapper,
  Button,
  HelperText,
} from "./question.styled";
import { QuestionItemType } from "./question.constants";

const Question = ({
  data,
  questionIndex,
  allQuestionsCounter,
  setCompleted,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [areAnswersSent, setAreAnswersSent] = useState(false);

  const selectAnswerId = (id) => {
    setAreAnswersSent(false);

    if (selectedAnswers.includes(id)) {
      setSelectedAnswers([...selectedAnswers.filter((answerId) => answerId !== id)]);
    } else {
      setSelectedAnswers(data.params.type === "multiple_choice" ? [...selectedAnswers, id] : [id]);
    }
  };

  const saveAnswer = () => {
    setAreAnswersSent(true);
    dispatch(ScrollLessonActions.setQuestionCompleted(selectedAnswers, setCompleted));
  };

  return (
    <Container>
      <Counter>
        {t("scrollLesson.finalQuiz.questionNumber", { questionNumber: questionIndex + 1 })}
        <AllQuestionsCounter>{` / ${allQuestionsCounter}`}</AllQuestionsCounter>
      </Counter>
      <Separator />
      <Title>
        {data.children[0].value}
      </Title>
      {data.params.type === "multiple_choice" && (
        <HelperText>{t("scrollLesson.finalQuiz.multipleChoice")}</HelperText>
      )}
      <Answers>
        {data.children.filter((item) => item.type === QuestionItemType.Answer).map((answer) => (
          <Answer
            key={answer.id}
            data={answer}
            isSelected={selectedAnswers.includes(answer.id)}
            setSelected={selectAnswerId}
          />
        ))}
      </Answers>
      <ButtonWrapper>
        <Button
          color={ButtonColor.Primary}
          disabled={!selectedAnswers.length || areAnswersSent}
          $large
          onClick={saveAnswer}
        >
          {t("scrollLesson.finalQuiz.confirm")}
        </Button>
      </ButtonWrapper>
    </Container>
  );
};

export default Question;
