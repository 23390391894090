import { Breakpoint, Button, CollapsibleBar, Color, Disclaimer, FontWeight, StyledComponent } from '@elearning-platform/ui';
import { css, RuleSet, styled } from 'styled-components';

import BgMobile from './images/bgMobile.jpg';
import { StepWrapperProps, TopBarProps } from './registration.types';

export const Container: StyledComponent = styled.div`
  display: flex;
`;

export const Wrapper: StyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
`;

export const MobileImage: StyledComponent = styled.div`
  background-image: url(${BgMobile});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  aspect-ratio: 375 / 200;
  min-height: 200px;
  max-height: 400px;
  width: 100%;
  position: relative;
`;

export const TopBar: StyledComponent<TopBarProps> = styled.div<TopBarProps>`
  display: flex;
  min-height: 68px;
  align-items: center;
  justify-content: space-between;
  padding: 13px 16px;
  border-bottom: 1px solid ${Color.White};
  border-top: 1px solid transparent;

  ${({ $noBorder }: TopBarProps): RuleSet<TopBarProps> | false => !!$noBorder && css`
    border-bottom: 1px solid transparent;
  `}

  @media ${Breakpoint.SmallDesktop} {
    padding: 23px 40px;
  }
`;

export const StepsMobileContainer: StyledComponent = styled.div`
  width: 100%;
  padding: 11px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${Color.Gray300};
`;

export const MobileCollapsibleBar: StyledComponent<typeof CollapsibleBar> = styled(CollapsibleBar)`
  width: 100%;
  height: inherit;
  border: none;
  padding: 0;
  justify-content: flex-start;
`;

export const LogoWrapper: StyledComponent = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
`;

export const Logo: StyledComponent = styled.img`
  display: block;
  height: 28px;

  @media ${Breakpoint.Tablet} {
    height: 32px;
  }
`;

export const ContentWrapper: StyledComponent = styled.div`
  display: flex;
`;

export const DesktopImage: StyledComponent = styled.img`
  display: block;
  width: 50%;
  max-width: 640px;
  /* Height = Screen height - TopBar Height and BottomBar height */
  height: calc(100vh - 164px);
  object-fit: cover;
  object-position: center;
`;

export const StepWrapper: StyledComponent<StepWrapperProps> = styled.div<StepWrapperProps>`
  display: none;

  ${({ $visible }: StepWrapperProps): RuleSet<StepWrapperProps> | false => $visible && css`
    display: block;
    width: 100%;
  `}
`;

export const Buttons: StyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media ${Breakpoint.SmallDesktop} {
    flex-direction: row;
    gap: 28px;
    align-items: center;
    position: relative;
  }
`;

export const BottomBar: StyledComponent = styled.div`
  padding: 24px 16px;
  border-top: 1px solid ${Color.Gray300};
  display: flex;
  align-items: center;
  margin-top: auto;

  @media ${Breakpoint.SmallDesktop} {
    padding: 12px 40px;
    min-height: 80px;
  }
`;

export const StyledDisclaimer: StyledComponent = styled(Disclaimer)`
  & a {
    color: ${Color.Gray800};
  }
`;

export const DisclaimerWrapper: StyledComponent = styled.div`
  display: flex;
  color: ${Color.Gray800};
`;

export const DisclaimerText: StyledComponent = styled.p``;

export const DisclaimerButton: StyledComponent<typeof Button> = styled(Button)`
  background-color: transparent;
  font-weight: ${FontWeight.Bold};
  font-size: inherit;
  line-height: inherit;
  padding: 0 4px;
  color: ${Color.Gray800};
`;
