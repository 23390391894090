import { Breakpoint, Color, StyledComponent } from '@elearning-platform/ui';
import { css, RuleSet, styled } from 'styled-components';

import { HeaderBaseProps } from './header.types';

export const Container: StyledComponent<HeaderBaseProps> = styled.div<HeaderBaseProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 68px;
  padding: 20px 16px;
  border-bottom: 1px solid ${Color.Gray300};

  ${({ $isDarkMode }: HeaderBaseProps): false | RuleSet<HeaderBaseProps> => $isDarkMode && css`
    border-color: ${Color.Gray600};
  `}

  @media ${Breakpoint.SmallDesktop} {
    padding: 24px 40px;
  }

  @media ${Breakpoint.Tablet} {
    border: none;
    max-height: 84px;
  }
`;

export const LogoImg: StyledComponent = styled.img`
  display: block;
  height: 28px;

  @media ${Breakpoint.Tablet} {
    height: 32px;
  }
`;

export const ButtonWrapper: StyledComponent = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Text: StyledComponent<HeaderBaseProps> = styled.p<HeaderBaseProps>`
  font-size: 12px;
  line-height: 1.5;
  text-align: right;
  color: ${Color.Gray700};

  ${({ $isDarkMode }: HeaderBaseProps): false | RuleSet<HeaderBaseProps> => $isDarkMode && css`
    color: ${Color.Gray400};
  `}
`;
