import {
  Breakpoint,
  CollapsibleBar,
  CollapsibleBarVariant,
  DisclaimerVariant,
  IconName,
  Steps,
  UseState,
} from '@elearning-platform/ui';
import React, { useEffect, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { AnyAction, Dispatch } from 'redux';

import { Path } from '../../../../enums';
import LogoDark from '../../../../images/logoFullDark.svg';
import LogoLight from '../../../../images/logoFullLight.svg';

import { Step1 } from './components/step1/step1.component';
import { Step2 } from './components/step2/step2.component';
import BgDesktop from './images/bgDesktop.jpg';
import { RegistrationActions } from './redux/registration.actions';
import {
  BottomBar,
  Container,
  ContentWrapper,
  DesktopImage,
  DisclaimerButton,
  DisclaimerText,
  DisclaimerWrapper,
  Logo,
  LogoWrapper,
  MobileCollapsibleBar,
  MobileImage,
  StepsMobileContainer,
  StepWrapper,
  StyledDisclaimer,
  TopBar,
  Wrapper,
} from './registration.styled';
import { RegistrationProps } from './registration.types';

export const Registration: React.FC<RegistrationProps> = ({
  onSignInClick,
}: RegistrationProps): JSX.Element => {
  const dispatch: Dispatch<AnyAction> = useDispatch();
  const { t }: TransProps<never> = useTranslation();
  const isSmallDesktop: boolean = useMediaQuery({
    query: Breakpoint.SmallDesktop,
  });
  const [currentStep, setCurrentStep]: UseState<number> = useState<number>(1);

  useEffect((): () => void => {
    dispatch(RegistrationActions.fetchForm());

    return (): void => {
      dispatch(RegistrationActions.clearForm());
    };
  }, [dispatch]);

  return (
    <Container>
      {!!isSmallDesktop && (
        <CollapsibleBar icon={IconName.ChevronRightDouble} onClick={onSignInClick} variant={CollapsibleBarVariant.Page} />
      )}
      <Wrapper>
        {!!isSmallDesktop && (
          <TopBar>
            <LogoWrapper onClick={onSignInClick}>
              <Logo src={LogoDark} />
            </LogoWrapper>
            <Steps
              currentStep={currentStep}
              maxSteps={2}
              stepText={t('registration.topBar.step')}
            />
          </TopBar>
        )}
        {!isSmallDesktop && (
          <>
            <MobileImage>
              <TopBar>
                <LogoWrapper onClick={onSignInClick}>
                  <Logo src={LogoLight} />
                </LogoWrapper>
              </TopBar>
            </MobileImage>
            <StepsMobileContainer>
              <MobileCollapsibleBar
                icon={IconName.ChevronLeftDouble}
                onClick={onSignInClick}
                variant={CollapsibleBarVariant.Page}
              />
              <Steps
                currentStep={currentStep}
                maxSteps={2}
                stepText={t('registration.topBar.step')}
              />
            </StepsMobileContainer>
          </>
        )}
        <ContentWrapper>
          {!!isSmallDesktop && <DesktopImage src={BgDesktop} />}
          <StepWrapper $visible={currentStep === 1}>
            <Step1
              goNext={(): void => setCurrentStep(2)}
              onSignInClick={onSignInClick}
            />
          </StepWrapper>
          <StepWrapper $visible={currentStep === 2}>
            <Step2 goBack={(): void => setCurrentStep(1)} />
          </StepWrapper>
        </ContentWrapper>
        <BottomBar>
          <StyledDisclaimer
            icon={IconName.Flare}
            title={t('registration.disclaimer.title')}
            variant={DisclaimerVariant.Brand}
            vertical={!isSmallDesktop}
          >
            <DisclaimerWrapper>
              <DisclaimerText>
                {t('registration.disclaimer.useTheOption')}
              </DisclaimerText>
              <DisclaimerButton label={t('registration.disclaimer.login')} onClick={onSignInClick} />
              <DisclaimerText>
                {t('registration.disclaimer.or')}
              </DisclaimerText>
              <DisclaimerButton internalPath={Path.RemindPassword} label={t('registration.disclaimer.remindPassword')} />
            </DisclaimerWrapper>
          </StyledDisclaimer>
        </BottomBar>
      </Wrapper>
    </Container>
  );
};
