import {
  Breakpoint,
  Button,
  ButtonSize,
  ButtonTheme,
  Color,
  ShapeVariant,
} from '@elearning-platform/ui';
import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { Path } from '../../../../enums';
import { Footer } from '../footer/footer.component';
import { FooterVariant } from '../footer/footer.enums';
import { Header } from '../header/header.component';
import { HeaderVariant } from '../header/header.enums';

import {
  ButtonWrapper,
  Container,
  EmptyDiv,
  EmptyShape,
  Grid,
  HelperText,
  MainText,
  ShapeBoxes,
  Span,
  StyledShape,
  StyledShape2,
  StyledShape3,
  TextBox,
  Wrapper,
} from './fail.styled';

export const Fail: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const isSmallDesktop: boolean = useMediaQuery({ query: Breakpoint.SmallDesktop });
  const isTablet: boolean = useMediaQuery({ query: Breakpoint.Tablet });

  return (
    <Container>
      <Wrapper>
        <Header variant={HeaderVariant.Dark} />
        <Grid>
          <EmptyShape />
          <StyledShape color={Color.Brand100} variant={ShapeVariant.Box} />
          {!!isSmallDesktop && <EmptyDiv />}
          <TextBox>
            <MainText>
              {t('emailVerification.yourLink')}
              <Span>{t('emailVerification.expired')}</Span>
            </MainText>
            <HelperText>{t('emailVerification.contactSupervisor')}</HelperText>
          </TextBox>
          {!!isSmallDesktop && (
            <ShapeBoxes>
              <StyledShape2 color={Color.Brand100} variant={ShapeVariant.Box} />
              <StyledShape3 color={Color.Gray600} variant={ShapeVariant.Box} />
            </ShapeBoxes>
          )}
          <ButtonWrapper>
            <Button
              internalPath={Path.Main}
              label={t('emailVerification.mainPage')}
              size={ButtonSize.Large}
              variant={ButtonTheme.PrimaryWhite}
            />
          </ButtonWrapper>
        </Grid>
      </Wrapper>
      {!isTablet && <Footer $variant={FooterVariant.Fail} />}
    </Container>
  );
};
