import { Color, StyledComponent, Typography } from '@elearning-platform/ui';
import { css, RuleSet, styled } from 'styled-components';

import { FooterVariant } from './footer.enums';
import { FooterProps } from './footer.types';

const footerVariants: { [_ in FooterVariant]: RuleSet<FooterProps> } = {
  [FooterVariant.Success]: css`
    background-color: ${Color.Black100};
  `,
  [FooterVariant.Fail]: css`
   background-color: ${Color.Gray700};
  `,
};

export const Container: StyledComponent<FooterProps> = styled.footer<FooterProps>`
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ $variant }: FooterProps): RuleSet<FooterProps> => footerVariants[$variant]}
`;

export const FooterText: StyledComponent<typeof Typography> = styled(Typography)`
  color: ${Color.Gray400};
`;
