import styled from "styled-components";

import { Button as ButtonBase } from "../../../../components/button/button.component";
import { Color, FontWeight } from "../../../../theme";

export const Container = styled.div`
  background-color: ${Color.white};
  padding: 25px 25px 50px;
`;

export const Counter = styled.p`
  font-size: 24px;
  font-weight: ${FontWeight.Bold};
  color: ${Color.deepGray};
`;

export const AllQuestionsCounter = styled.span`
  color: ${Color.gray1};
`;

export const Separator = styled.div`
  height: 2px;
  background-color: ${() => Color.brandBasic};
  margin-top: 15px;
  margin-bottom: 35px;
`;

export const Title = styled.p`
  font-size: 26px;
  color: ${Color.deepGray};
`;

export const HelperText = styled.p`
  margin-top: 10px;
  font-size: 14px;
  line-height: 17px;
  color: ${Color.gray9};
`;

export const Answers = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
`;

export const Button = styled(ButtonBase)`
  margin-left: auto;
  min-width: 200px;
`;
