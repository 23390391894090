import { Breakpoint, StyledComponent } from '@elearning-platform/ui';
import { css, RuleSet, styled } from 'styled-components';

import { SliderProps } from './authView.types';

export const Container: StyledComponent = styled.div`
  overflow: hidden;
  width: 100%;
`;

export const Slider: StyledComponent<SliderProps> = styled.div<SliderProps>`
  display: flex;
  width: 200%;
  transition: transform 0.5s ease;

  ${({ $isLoginView }: SliderProps): RuleSet<SliderProps> => $isLoginView ? css`
    transform: translateX(0);
    height: 100vh;
  ` : css`
    transform: translateX(-50%);
  `}
`;

export const ViewWrapper: StyledComponent = styled.div`
  width: 50%;

  @media ${Breakpoint.SmallDesktop} {
    height: 100vh;
  }
`;
