import { UserContextTypes } from "../../userContext/redux/userContext.reducer";
import { CommonTypes } from "../../redux/common/common.reducer";
import { clearApiCache } from "../../helpers/clearApiCache";
import { logger } from "../../helpers/logger";
import registerServiceWorker from "../../registerServiceWorker";
import { unregister } from "../../registerServiceWorker";
import { store } from "../../store";
import { AppConfig } from "../../appConfig";

import { ErrorName } from "./errorHandlers.constants";

const errorHandler = (errorName, errorDetails, shouldClearCache = false) => {
  if (AppConfig.errorHandlingEnabled) {
    logger(errorName, errorDetails);

    if (shouldClearCache) {
      store.dispatch({ type: UserContextTypes.SET_LOGGED_OUT, data: {} });
      clearApiCache();
      unregister();
      window.location.replace("/");
      registerServiceWorker();
      store.dispatch({ type: CommonTypes.SET_GLOBAL_ERROR_VISIBLE, status: true });
    }
  }
};

const windowOnErrorHandler = (errorMessage, urlSource, lineNo, columnNo, errorObject) => {
  const errorObjectInString = JSON.stringify(errorObject);
  const errorDetails = {
    ...{ errorMessage },
    ...{ urlSource },
    ...{ lineNo },
    ...{ columnNo },
    errorObject: errorObjectInString,
  };

  if (errorDetails.errorMessage !== ErrorName.ResizeObserver
    && errorDetails.errorMessage !== ErrorName.ScriptError) {
    errorHandler(ErrorName.GlobalError, errorDetails, true);
  }
  return true; //When the function returns true, this prevents the firing of the default event handler.
};

const windowOnUnhandledRejectionHandler = (error) => {
  const errorDetails = {
    "reason": error.reason,
  };

  errorHandler(ErrorName.UnhandledRejection, errorDetails);
}

export const globalErrorHandler = () => {
  window.onerror = windowOnErrorHandler;
  window.onunhandledrejection = windowOnUnhandledRejectionHandler;
}
