import React from "react";

import LogoSvg from "../../images/logoDark.svg";

import { Wrapper, LogoImg } from "./logo.styled";

const Logo = () => (
  <Wrapper>
    <LogoImg src={LogoSvg} />
  </Wrapper>
);

export default Logo;
