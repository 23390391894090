import {
  Breakpoint,
  Button,
  ButtonSize,
  ButtonTheme,
  IconName,
} from '@elearning-platform/ui';
import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

import { AppConfig } from '../../../../appConfig';
import { Path } from '../../../../enums';
import LogoDark from '../../../../images/logoFullDark.svg';
import LogoLight from '../../../../images/logoFullLight.svg';

import { HeaderVariant } from './header.enums';
import { ButtonWrapper, Container, LogoImg, Text } from './header.styled';
import { HeaderProps } from './header.types';

export const Header: React.FC<HeaderProps> = ({
  variant,
}: HeaderProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const isTablet: boolean = useMediaQuery({ query: Breakpoint.Tablet });
  const isDark: boolean = variant === HeaderVariant.Dark;

  return (
    <Container $isDarkMode={isDark}>
      <Link to={Path.Main}>
        <LogoImg src={isDark ? LogoLight : LogoDark} />
      </Link>
      {!!isTablet && (
        <ButtonWrapper>
          <Text $isDarkMode={isDark}>{t('emailVerification.contactText')}</Text>
          <Button
            externalUrl={`mailto:${AppConfig.platformContactEmail}`}
            icon={IconName.EmailOutlined}
            label={t('emailVerification.help')}
            reversed
            size={ButtonSize.Medium}
            variant={isDark ? ButtonTheme.SecondaryDoubleWhite : ButtonTheme.SecondaryGray}
          />
        </ButtonWrapper>
      )}
    </Container>
  );
};
