import { Button, ButtonSize, ButtonTheme, IconName, TypographyVariant } from '@elearning-platform/ui';
import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { AppConfig } from '../../../../appConfig';

import { Container, FooterText } from './footer.styled';
import { FooterProps } from './footer.types';

export const Footer: React.FC<FooterProps> = ({ $variant }: FooterProps): React.JSX.Element => {
  const { t }: TransProps<never> = useTranslation();

  return (
    <Container $variant={$variant}>
      <FooterText variant={TypographyVariant.TextXS}>
        {t('emailVerification.contactText')}
      </FooterText>
      <Button
        externalUrl={`mailto:${AppConfig.platformContactEmail}`}
        icon={IconName.EmailOutlined}
        label={t('emailVerification.help')}
        reversed
        size={ButtonSize.Medium}
        variant={ButtonTheme.SecondaryDoubleWhite}
      />
    </Container>
  );
};
