import { UseState } from '@elearning-platform/ui';
import React, { useState } from 'react';
import { Location, useLocation } from 'react-router';

import PasswordReset from '../../userRegistration/passwordReset';

import { Container, Slider, ViewWrapper } from './authView.styled';
import { LoginView } from './components/loginView/loginView';
import { Registration } from './components/registration/registration';

export const AuthView: React.FC = (): JSX.Element => {
  const location: Location = useLocation();
  const hash: string = location.hash;
  const [isLoginView, setIsLoginView]: UseState<boolean> = useState<boolean>(true);

  if (hash === '#aktywacja') {
    return <PasswordReset activate={true} />;
  } else if (hash === '#zmienhaslo') {
    return <PasswordReset passwordReset={true} />;
  }

  return (
    <Container>
      <Slider $isLoginView={isLoginView}>
        <ViewWrapper>
          <LoginView onSignUpClick={(): void => setIsLoginView(false)} />
        </ViewWrapper>
        <ViewWrapper>
          <Registration onSignInClick={(): void => setIsLoginView(true)} />
        </ViewWrapper>
      </Slider>
    </Container>
  );
};
