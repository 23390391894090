import { Breakpoint, Button, Color, InfoChip, Shape, StyledComponent, Typography } from '@elearning-platform/ui';
import { styled } from 'styled-components';

import BgLarge from './images/bgLarge.jpg';
import BgSmall from './images/bgSmall.jpg';

export const Container: StyledComponent = styled.div`
  background-image: url(${BgSmall});
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  @media ${Breakpoint.Tablet} {
    background-image: url(${BgLarge});
  }
`;

export const Header: StyledComponent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px 19px;
  border-bottom: 1px solid ${Color.Gray300};

  @media ${Breakpoint.Tablet} {
    padding: 24px 40px;
    border-bottom: none;
  }
`;

export const Logo: StyledComponent = styled.img`
  display: block;
  height: 28px;

  @media ${Breakpoint.Tablet} {
    height: 32px;
  }
`;

export const HeaderButtons: StyledComponent = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Content: StyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 28px 20px 78px;
  /* Min height equals view height - top bar */
  min-height: calc(100vh - 68px);

  @media ${Breakpoint.Tablet} {
    padding: 148px 96px 112px;
    gap: 48px;
    max-width: 710px;
    min-height: unset;
    margin-top: auto;
  }
`;

export const TitleWrapper: StyledComponent = styled.div`
  margin-bottom: auto;
`;

export const Title: StyledComponent = styled(Typography)`
  color: ${Color.Gray400};
`;

export const PlatformName: StyledComponent = styled(Typography)`
  color: ${Color.White};
`;

export const Form: StyledComponent = styled.form`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

export const Inputs: StyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media ${Breakpoint.Tablet} {
    max-width: 436px;
  }
`;

export const FormButtons: StyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media ${Breakpoint.Tablet} {
    flex-direction: row;
    align-items: center;
    gap: 28px;
  }
`;

export const SignInButton: StyledComponent = styled(Button)`
  min-width: 240px;
`;

export const BottomBar: StyledComponent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 50%;
  background-color: ${Color.Black100};
`;

export const StyledInfoChip: StyledComponent = styled(InfoChip)`
  a {
    color: ${Color.Yellow900};
  }

  @media ${Breakpoint.Tablet} {
    width: fit-content;
  }
`;

export const StyledShape: StyledComponent = styled(Shape)`
  display: none;

  @media ${Breakpoint.Tablet} {
    display: block;
    width: 276px;
    height: 276px;
    position: absolute;
    right: -100px;
    bottom: -100px;
  }
`;
