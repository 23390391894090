/* eslint-disable @typescript-eslint/no-explicit-any */
import { handleApiError, phpApi } from '../../../../../api';
import { ApiResponse } from '../../../../../api/api.types';

// TODO: remove these ANY
export const fetchForm: () => Promise<any> = (): Promise<any> => (
  phpApi.get('/api/user/register').catch(handleApiError)
);

// TODO: remove these ANY
export const fetchCompanies: (division: string) => Promise<any> = (division: string): Promise<any> => (
  phpApi.get(`/api/static/companies/${division}`).catch(handleApiError)
);

// TODO: remove these ANY
export const fetchDuties: (division: string) => Promise<any> = (division: string): Promise<any> => (
  phpApi.get(`/api/static/duties/${division}`).catch(handleApiError)
);

// TODO: remove these ANY
export const sendForm: (data: any) => Promise<any> = (data: any): Promise<any> => (
  phpApi.post('/api/user/register', data).catch((error: ApiResponse<void>): ApiResponse<void> => error)
);
