import {
  Breakpoint,
  Button,
  ButtonSize,
  ButtonTheme,
  CollapsibleBar,
  CollapsibleBarVariant,
  Color,
  DoubleChip,
  DoubleChipSize,
  DoubleChipVariant,
  FontWeight,
  Icon,
  IconName,
  ShapeVariant,
  TextButton,
  TextButtonTheme,
  Typography,
  TypographyVariant,
  UseState,
} from '@elearning-platform/ui';
import React, { useEffect, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { selectDuties } from '../../redux/registration.selectors';
import { Duty } from '../../registration.types';

import {
  Buttons,
  CloseButton,
  Dot,
  DrawerBox,
  DrawerContent,
  DrawerTitle,
  Duties,
  DutyGroup,
  DutyGroups,
  GroupTitleWrapper,
  StyledDrawer,
  StyledShape,
  TitleLowerText,
  TitleUpperText,
} from './dutyDrawer.styled';
import { DutyDrawerProps } from './dutyDrawer.types';

export const DutyDrawer: React.FC<DutyDrawerProps> = (props: DutyDrawerProps): JSX.Element => {
  const { open, onClose, initDuties, confirmDuties }: DutyDrawerProps = props;
  const { t }: TransProps<never> = useTranslation();
  const isTablet: boolean = useMediaQuery({ query: Breakpoint.Tablet });
  const duties: Duty[] | null = useSelector(selectDuties) as Duty[] | null;
  const dutyGroups: string[] | undefined = duties?.reduce(
    (currentState: string[], nextDuty: Duty): string[] => {
      if (!currentState.includes(nextDuty.groupName)) {
        return [...currentState, nextDuty.groupName];
      } else {
        return [...currentState];
      }
    },
    [],
  );
  const [selectedDuties, setSelectedDuties]: UseState<Duty[]> = useState<Duty[]>(initDuties);

  useEffect((): void => {
    setSelectedDuties(initDuties);
  }, [initDuties]);

  const addDuty: (duty: Duty) => void = (duty: Duty): void => {
    if (!selectedDuties.find((searchDuty: Duty): boolean => searchDuty.id === duty.id)) {
      setSelectedDuties([...selectedDuties, duty]);
    }
  };

  const removeDuty: (id: number) => void = (id: number): void => {
    setSelectedDuties(selectedDuties.filter((duty: Duty): boolean => duty.id !== id));
  };

  const onCancel: () => void = (): void => {
    setSelectedDuties(initDuties);
    onClose();
  };

  const handleConfirmDuties: () => void = (): void => {
    confirmDuties(selectedDuties);
    onClose();
  };

  return (
    <StyledDrawer onClose={onClose} open={open}>
      <DrawerBox>
        {isTablet ? (
          <CollapsibleBar icon={IconName.ChevronRightDouble} onClick={onClose} variant={CollapsibleBarVariant.Popup} />
        ) : (
          <CloseButton onClick={onClose}>
            <Icon name={IconName.Clear} size={24} />
          </CloseButton>
        )}
        <DrawerContent>
          <DrawerTitle>
            <TitleUpperText variant={isTablet ? TypographyVariant.DisplaySM : TypographyVariant.DisplayXS}>
              {t('registration.step2.chooseDuties')}
            </TitleUpperText>
            <TitleLowerText variant={isTablet ? TypographyVariant.DisplaySM : TypographyVariant.DisplayXS}>
              {t('registration.step2.chooseDuties2')}
            </TitleLowerText>
          </DrawerTitle>
          <DutyGroups>
            {dutyGroups?.map((group: string): JSX.Element => (
              <DutyGroup key={group}>
                <GroupTitleWrapper>
                  <Dot />
                  <Typography
                    fontWeight={FontWeight.Bold}
                    variant={TypographyVariant.Subheader}
                  >
                    {group}
                  </Typography>
                </GroupTitleWrapper>
                <Duties>
                  {duties?.filter((duty: Duty): boolean => duty.groupName === group).map((duty: Duty): JSX.Element => {
                    const selected: boolean = selectedDuties.includes(duty);

                    return (
                      <DoubleChip
                        key={duty.id}
                        onClick={(): void => selected ? removeDuty(duty.id) : addDuty(duty)}
                        primaryText={group}
                        secondaryText={duty.name}
                        size={isTablet ? DoubleChipSize.Small : DoubleChipSize.Medium}
                        variant={selected ? DoubleChipVariant.Selected : DoubleChipVariant.Default}
                      />
                    );
                  })}
                </Duties>
              </DutyGroup>
            ))}
          </DutyGroups>
          <Buttons>
            <Button
              fullWidth={!isTablet}
              label={t('registration.step2.confirm')}
              onClick={handleConfirmDuties}
              size={ButtonSize.Medium}
              variant={ButtonTheme.PrimaryBrand}
            />
            <TextButton
              fullWidth={!isTablet}
              label={t('registration.step2.cancel')}
              onClick={onCancel}
              variant={TextButtonTheme.TertiaryGrey}
            />
          </Buttons>
        </DrawerContent>
        <StyledShape color={Color.Gray300} variant={ShapeVariant.Circle1} />
      </DrawerBox>
    </StyledDrawer>
  );
};
